import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import TournamentCreation from '../../components/tournament-creation';

const NewTournamentPage = () => (
  <Layout>
    <h1>Create a New Tournament</h1>
    <div className="stripe-hr thin" />
    <p>
      Save 50% by
      {' '}
      <Link
        to="/tournament/ten-new/"
      >
        buying ten tournaments
      </Link>
      .
    </p>
    <p>
      Not quite ready yet?
      {' '}
      Learn more about how to run a tournament:
      {' '}
      <Link to="/tournament/instructions/">
        Instructions
      </Link>
    </p>
    <h2>Create a Tournament</h2>
    <TournamentCreation price={1900} />
  </Layout>
);

export default NewTournamentPage;
